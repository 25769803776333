import comAxios from "@/utils/communucationApi";

export default {
  namespaced: true,
  state: {
    ratingTemplate: [],
    ratingQuestions: [],
    rateId: null,
  },
  getters: {
    getTicketTemplate(state) {
      return (
        state.ratingTemplate.find((template) => template.caption == "Ticket") ||
        []
      );
    },
    getRatingQuestions: (state) => state.ratingQuestions,
  },
  mutations: {
    setRatingTemplate(state, payload) {
      state.ratingTemplate = payload;
    },
    setRatingQuestions(state, payload) {
      state.ratingQuestions = payload;
    },
    setRateId(state, payload) {
      state.rateId = payload;
    },
  },
  actions: {
    async fetchAllRatingTemplate({ commit, getters, dispatch }) {
      await comAxios
        .get("api/RatingTemplate")
        .then((response) => {
          commit("setRatingTemplate", response.data);
          dispatch("getQuestions", getters.getTicketTemplate.id);
        })
        .catch((error) => {});
    },
    async getQuestions({ commit }, id) {
      await comAxios
        .get("api/RatingTemplate/templatequestions/" + id)
        .then((response) => {
          commit("setRatingQuestions", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchRating({ commit }, payload) {
      const { userId, ticketId } = payload;
      const rate = {
        userId: userId,
        context: ticketId,
      };
      await comAxios
        .post("api/Rate", rate)
        .then((response) => {
          commit("setRateId", response.data.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async addQuestion({ state, getters }, payload) {
      const { questionId, score, userId } = payload;
      const value = {
        rateId: state.rateId,
        questionId: questionId,
        score: score,
        ratingTemplateId: getters.getTicketTemplate.id,
        userId: userId,
      };
      await comAxios
        .post("api/Rate/questionscore", value)
        .then((response) => {})
        .catch((error) => {});
    },
  },
};

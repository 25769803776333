import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
let userTracker = null;
let customerSupportHub = null;
let signalRUrl;

switch (window.location.hostname) {
  case "b2b.ucantay.com":
    signalRUrl = "https://communication.d7ksuper.com/sgnl/";
    break;
  default:
    signalRUrl = "https://communication.d7ksuper.com/dev-sgnl/"; // Varsayılan bir URL belirleyin
}

let defaultUrl = signalRUrl;

function initializeUserTracker(token) {
  userTracker = new HubConnectionBuilder()
    .withUrl(defaultUrl + "usertrackerhub", {
      accessTokenFactory: () => token,
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .build();
  return userTracker;
}
function initializeCustomerSupportHub(token) {
  customerSupportHub = new HubConnectionBuilder()
    .withUrl(defaultUrl + "customersupporthub", {
      accessTokenFactory: () => token,
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .build();

  return customerSupportHub;
}

export { initializeUserTracker, initializeCustomerSupportHub };

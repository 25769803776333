import {
  initializeUserTracker,
  initializeCustomerSupportHub,
} from "@/utils/signalR";
import comAxios from "@/utils/communucationApi";

export default {
  namespaced: true,
  state: {
    key: {
      userConnected: "UserConnected",
      userDisconnect: "UserDisconnected",
      upodateUserCount: "UserCountChanged",
      joinGroup: "JoinGroup",
      leaveGroup: "LeaveGroup",
      totalUser: "CustomerSupportTotalUserCountChanged",
      CreateTicket: "CreateTicket",
      UpdateTicket: "UpdateTicket",
      DeleteTicket: "DeleteTicket",
      SendMessage: "SendMessage",
      SendTypingNotification: "SendTypingNotification",
      TicketCreated: "TicketCreated",
      TicketUpdated: "TicketUpdated",
      TicketDeleted: "TicketDeleted",
      NewMessage: "NewMessage",
      Typing: "Typing",
      CustomerAgentsGroup: "CustomerAgents",
      joinCompany: "JoinMyCompanyGroup",
    },
    userId: JSON.parse(localStorage.getItem("B2BUserData"))
      ? JSON.parse(localStorage.getItem("B2BUserData")).id
      : null,
    user: JSON.parse(localStorage.getItem("B2BUserData")) || null,
    userToken: localStorage.getItem("B2BUserToken") || null,
    ticket: [],
    messageList: [],
    userTrackerConnection:
      localStorage.getItem("userTrackerConnection") || null,
    customerSupportHubConnection:
      localStorage.getItem("customerSupportHubConnection") || null,
    isTyping: false,
    userTracker: null,
    customerSupportHub: null,
    onGroupList: [],
    signalRToken: localStorage.getItem("SignalRToken") || null,
    updateTicket: null,
  },
  mutations: {
    resetState(state) {
      state.userId = null;
      state.user = null;
      state.userToken = null;
      state.ticket = [];
      state.messageList = [];
      state.userTrackerConnection = null;
      state.customerSupportHubConnection = null;
      state.isTyping = false;
      state.userTracker = null;
      state.customerSupportHub = null;
      state.onGroupList = [];
      state.signalRToken = null;
    },
    setSignalRToken(state, payload) {
      state.signalRToken = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setToken(state, payload) {
      state.userToken = payload;
    },
    setIsTyping(state, payload) {
      state.isTyping = payload;
    },
    setBackChat(state) {
      state.ticket = [];
    },
    setMessageList(state, message) {
      const isTicketIn = state.messageList.find(
        (element) =>
          message.ChatId === element.id || message.chatId === element.id
      );
      if (!isTicketIn) {
        this.commit("signalRStore/addTicketIdInMessageList", message);
      }
      state.messageList.forEach((element) => {
        if (element.id == message.ChatId || message.chatId === element.id) {
          element.messages.push(message);
        }
      });
    },
    addTicketIdInMessageList(state, payload) {
      const chatId = {
        id: payload.chatId || payload.ChatId,
        messages: payload.messages ? payload.messages[0] : [],
      };
      state.messageList.push(chatId);
    },

    setUserId(state, id) {
      state.userId = id;
    },
    setUserToken(state, token) {
      state.userToken = token;
    },
    userTrackerStart(state) {},
    setTicket(state, ticket) {
      state.ticket = { data: ticket };
    },
    createTicketOn(state, ticketValue) {
      const ticket = {
        description: ticketValue.textTicket,
        priority: ticketValue.priority,
        subject: ticketValue.textAbout,
        userId: state.userId,
      };
      state.customerSupportHub
        .invoke(state.key.CreateTicket, ticket)
        .then((data) => {
          state.ticket = data;
          this.commit("signalRStore/joinGroup");
        });
    },
    joinGroup(state) {
      const ticketChat = "TicketChat_" + state.ticket.data.id;
      state.customerSupportHub.invoke(state.key.joinGroup, ticketChat);
      this.commit("signalRStore/listenTyping");
    },
    leaveGroup(state) {
      const ticketChat = "TicketChat_" + state.ticket.data?.id || null;
      if (state.ticket.data?.id) {
        state.customerSupportHub.invoke(state.key.leaveGroup, ticketChat);
        this.commit("signalRStore/setBackChat");
      }
    },
    listenMessage(state) {
      state.customerSupportHub.on(state.key.NewMessage, (data) => {
        this.commit("signalRStore/setMessageList", data);
      });
    },
    updateMessageSentDate(state, payload) {
      const selectedMessage = state.messageList.find((msg) => {
        return msg.id == payload.chatId;
      });
      const index = selectedMessage.messages.findIndex(
        (msg) => msg.Id === payload.id
      );
      if (index !== -1) {
        const utcDate = new Date(payload.sentDate);
        const hours = utcDate.getHours();
        const minutes = utcDate.getMinutes();
        const formattedMinute = minutes < 10 ? `0${minutes}` : `${minutes}`;

        const sentDate = `${hours}:${formattedMinute}`;
        const updatedMessage = {
          ...selectedMessage.messages[index],
          sentDate: sentDate,
        };
        selectedMessage.messages = [
          ...selectedMessage.messages.slice(0, index),
          updatedMessage,
          ...selectedMessage.messages.slice(index + 1),
        ];
      }
    },
    sendMessage(state, messageValue) {
      const message = {
        Id: messageValue.id,
        ChatId: state.ticket.data.id,
        Message: messageValue.messageText,
        SenderId: state.userId,
      };

      state.customerSupportHub
        .invoke(state.key.SendMessage, message)
        .then((data) => {})
        .catch((err) => {});
    },
    listenTyping(state) {
      state.customerSupportHub.on(state.key.Typing, (data) => {
        if (data.userId !== this.getters["signalRStore/getUserId"]) {
          this.commit("signalRStore/setIsTyping", data.isTyping);
        }
      });
    },
    checkTicket(state, payload) {
      if (
        state.ticket.data &&
        state.ticket.data.id &&
        state.ticket.data.id == payload.id
      ) {
        state.ticket.data = payload;
      }
    },

    setOldMessages(state, payload) {
      if (state.messageList.find((item) => item.id == state.ticket.data.id)) {
        state.messageList.forEach((item) => {
          if (item.id == state.ticket.data.id) {
            item.messages = payload;
          }
        });
      } else {
        const message = { chatId: state.ticket.data.id, messages: [payload] };
        this.commit("signalRStore/addTicketIdInMessageList", message);
      }
    },
    resetTicket(state) {
      state.ticket = [];
    },
    updateTicket(state, payload) {
      state.updateTicket = payload;
    },
  },
  getters: {
    getIsTyping(state) {
      return state.isTyping;
    },
    getUserId(state) {
      return state.userId;
    },
    getToken(state) {
      return state.userToken;
    },
    getUser(state) {
      return state.user;
    },
    getTicket(state) {
      return state.ticket;
    },
    getMessageList(state) {
      const foundMessage = state.messageList.find((message) => {
        if (state.ticket.data) {
          return message.id === state.ticket.data.id;
        } else {
          return [];
        }
      });
      foundMessage && foundMessage.messages
        ? foundMessage.messages.sort(
            (a, b) => new Date(a.sentDate) - new Date(b.sentDate)
          )
        : "";
      return foundMessage && foundMessage.messages ? foundMessage.messages : [];
    },
    getUpdateTicket(state) {
      return state.updateTicket;
    },
  },
  actions: {
    async ticketUpdated({ commit }, payload) {},
    connectUseTrackerHub({ state, commit }) {
      state.userTracker = initializeUserTracker(state.signalRToken);
      state.userTracker
        .start()
        .then(() => {
          localStorage.setItem(
            "userTrackerConnection",
            state.userTracker.connectionId
          );
        })
        .catch((err) => {
          console.error("Error establishing SignalR connection:", err);
          dispatch("addToken");
        });
    },
    async connectCustomerSupportHub({ commit, dispatch }, ticket) {
      await dispatch("customerSupportHubStart");
    },
    async createTicket({ commit }, ticket) {
      return new Promise((resolve, reject) => {
        try {
          commit("createTicketOn", ticket); // Örneğin, mutation çağrısı
          resolve({ status: 200 });
        } catch (err) {
          reject(err);
        }
      });
    },
    async sendMessage({ commit }, message) {
      commit("sendMessage", message);
    },
    async connectSelectedTicket({ commit }, ticket) {
      const setTicket = {
        ...ticket,
      };
      commit("setTicket", setTicket);
    },
    async backChat({ commit }) {
      // commit("leaveGroup");
    },
    sendTyping({ state }, payload) {
      state.customerSupportHub.invoke(
        state.key.SendTypingNotification,
        payload,
        payload.ChatId
      );
    },
    async addToken({ commit }, payload) {
      await comAxios
        .get("api/AccessTokens")
        .then((response) => {
          commit("setSignalRToken", response.data);
          localStorage.setItem("SignalRToken", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      if (payload) {
        commit("setToken", payload);
      }
    },
    async addUserAndUserId({ commit }, payload) {
      commit("setUser", payload);
      commit("setUserId", payload.id);
    },
    async closeConnection({ state, dispatch }) {
      await state.customerSupportHub.stop();
      await state.userTracker.stop();
      state.userId = null;
      state.user = null;
      state.userToken = null;
      state.ticket = [];
      state.messageList = [];
      state.userTrackerConnection = null;
      state.customerSupportHubConnection = null;
      state.isTyping = false;
      state.userTracker = null;
      state.customerSupportHub = null;
      dispatch("resetState");
    },
    async fetchAllMessage({ state, commit }) {
      comAxios
        .get(`/api/Messages/${state.ticket.data.id}`)
        .then((response) => {
          commit("setOldMessages", response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async resetState({ commit, dispatch }) {
      commit("resetState");
      dispatch("customerSupportStore/resetCustomerSupport", null, {
        root: true,
      });
    },
    async listenTicket({ commit, state, dispatch }) {
      state.customerSupportHub.on(state.key.TicketUpdated, (data) => {
        commit("updateTicket", data);
      });
    },
    async customerSupportHubStart({ state, commit, dispatch }) {
      state.customerSupportHub = initializeCustomerSupportHub(
        state.signalRToken
      );
      state.customerSupportHub
        .start()
        .then(() => {
          localStorage.setItem("customerSupportHubConnection", "true");
          commit("listenMessage");
          dispatch("listenTicket");
        })
        .catch((error) => {
          dispatch("addToken");
        });
    },
    async checkSelectedTicket({ commit }, payload) {
      commit("checkTicket", payload);
    },
    async updateIsRating({ commit, state }, payload) {
      const ticket = { ...payload.data, isRated: true };
      state.customerSupportHub.invoke(
        state.key.UpdateTicket,
        ticket,
        payload.data
      );
    },
  },
};

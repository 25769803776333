import comAxios from "@/utils/communucationApi";

export default {
  namespaced: true,
  state: {
    startNewChat: false,
    connectionStart: false,
    selectTicket: null,
    messageText: "",
    isWidgetOpen: false,
    isChatOpen: false,
    chatType: "suggestion",
    customerSupportChat: false,
    ticketText: "",
    ticketAbout: "",
    busy: false,
    selectPriority: null,
    options: [
      { value: null, text: "Öncelik Seçiniz" },
      { value: 4, text: "Acil" },
      { value: 3, text: "Öncelikli" },
      { value: 2, text: "Normal" },
      { value: 1, text: "Düşük" },
    ],
    messageList: [],
    ticketList: [],
    connectedUser: [],
    idList: [],
    connectedNameList: [],
  },
  getters: {
    getCustomerSupportChat: (state) => state.customerSupportChat,
    getCustomerConnectedUser(state) {
      return state.connectedNameList;
    },
    getTicketList(state) {
      // return state.ticketList.filter((ticket) => {
      //   return ticket.status === 1;
      // });
      return state.ticketList.sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      );
    },
    getConnectionStart: (state) => state.connectionStart,
    getStartNewChat: (state) => state.startNewChat,
    getSelectTicket: (state) => state.selectTicket,
    getOptions: (state) => state.options,
    getSelectPriority: (state) => state.selectPriority,
    getTicketAbout: (state) => state.ticketAbout,
    getTicketText: (state) => state.ticketText,
    getBusy: (state) => state.busy,
    getMessageText: (state) => state.messageText,
    getConnectedNameList: (state) => state.connectedNameList,
  },
  mutations: {
    resetCustomerSupport(state) {
      state.startNewChat = false;
      state.connectionStart = false;
      state.selectTicket = null;
      state.messageText = "";
      state.isWidgetOpen = false;
      state.isChatOpen = false;
      state.chatType = "suggestion";
      state.customerSupportChat = false;
      state.ticketText = "";
      state.ticketAbout = "";
      state.busy = false;
      state.selectPriority = null;
      state.messageList = [];
      state.ticketList = [];
      state.connectedUser = [];
      state.idList = [];
      state.connectedNameList = [];
    },

    setConnectedUser(state, payload) {
      state.connectedUser.push(payload);
    },
    setTicketList(state, payload) {
      state.ticketList = payload;
    },
    setMessageText(state, payload) {
      state.messageText = payload;
    },
    setBusy(state, payload) {
      state.busy = payload;
    },
    setTicketText(state, payload) {
      state.ticketText = payload;
    },
    setTicketAbout(state, payload) {
      state.ticketAbout = payload;
    },
    setSelectPriority(state, payload) {
      state.selectPriority = payload;
    },
    setCustomerSupportChat(state, payload) {
      state.customerSupportChat = payload;
    },
    setTicketList(state, payload) {
      state.ticketList = payload;
    },
    setConnectionStart(state, payload) {
      state.connectionStart = payload;
    },
    setSelectTicket(state, payload) {
      state.selectTicket = payload;
    },
    setStartNewChat(state, payload) {
      state.startNewChat = payload;
    },
    resetChat(state) {
      state.startNewChat = false;
      state.connectionStart = false;
      state.selectTicket = null;
      state.messageText = "";
      state.selectPriority = null;
      this.dispatch("signalRStore/backChat", null, { root: true });
      this.dispatch("customerSupportStore/getAllTickets", null, { root: true });
      state.connectedUser = [];
    },
    addId(state, payload) {
      state.idList.push(payload.toString());
    },
    setIdName(state, payload) {
      payload.forEach((item) => {
        if (
          !state.connectedNameList.find(
            (nameList) => nameList.id == item.userIdentifier
          )
        ) {
          state.connectedNameList.push({
            id: item.userIdentifier,
            name: item.firstName,
            lastName: item.lastName,
          });
        }
      });
    },
    updateTicketList(state, payload) {
      const index = state.ticketList.findIndex(
        (ticket) => ticket.id === payload.id
      );
      if (index !== -1) {
        state.ticketList.splice(index, 1, { ...payload });
      }
    },
  },
  actions: {
    getUserDatas({ state, commit, dispatch }) {
      state.ticketList.forEach((ticket) => {
        ticket.handlerIds.forEach((handlerId) => {
          let connectedUser = [];
          if (!connectedUser.find((id) => id == handlerId)) {
            connectedUser.push(handlerId.toString());
          }
          if (!state.idList.find((id) => id == handlerId)) {
            commit("addId", handlerId);
          }
          let ticketConnected = {
            id: ticket.id,
            handlerId: connectedUser,
          };
          commit("setConnectedUser", ticketConnected);
        });
        if (
          !state.idList.find((id) => {
            if (ticket.responderIDs[0]) {
              return id == ticket.responderIDs[0].toString();
            }
          })
        ) {
          if (ticket.responderIDs[0]) {
            commit("addId", ticket.responderIDs[0].toString());
          }
        }
      });
    },
    getAllTickets({ commit, state, dispatch }) {
      comAxios
        .get("api/Ticket")
        .then((response) => {
          commit("setTicketList", response.data);
          dispatch("getUserDatas");
          dispatch("getData");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getData({ state, commit }) {
      if (state.idList.length > 0) {
        comAxios
          .post(`/api/UserData`, state.idList)
          .then((response) => {
            commit("setIdName", response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async addIdList({ commit, state }, userId) {
      if (!state.idList.find((id) => id == userId)) {
        commit("addId", userId);
      }
    },
    async resetCustomerSupport({ commit }) {
      commit("resetCustomerSupport");
    },
    async updateTicketList({ commit }, data) {
      commit("updateTicketList", data);
    },
  },
};

import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: "https://dev-api.d7ksuper.com/api/",
})

Vue.prototype.$http = axiosIns

export default axiosIns

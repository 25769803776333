// axios.js dosyası
import axios from "axios";
let signalRUrl;

switch (window.location.hostname) {
  case "b2b.ucantay.com":
    signalRUrl = "https://communication.d7ksuper.com/sgnl/";
    break;
  default:
    signalRUrl = "https://communication.d7ksuper.com/dev-sgnl/"; // Varsayılan bir URL belirleyin
}

let defaultUrl = signalRUrl;

const comAxios = axios.create({
  baseURL: defaultUrl, // API'nin temel URL'si
  timeout: 10000, // İstek zaman aşımı (ms cinsinden)
});

comAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("B2BUserToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default comAxios;

import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import signalRStore from "./signalR/signalRStore.js";
import customerSupportStore from "./customerSupport/customerSupportStore";
import ratingStore from "./rating/ratingStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    signalRStore,
    customerSupportStore,
    ratingStore,
  },
  strict: process.env.DEV,
});

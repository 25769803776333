import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/urunler',
      name: 'urunler',
      component: () => import('@/views/Urunler.vue'),
      meta: {
        pageTitle: 'Ürünler',
        breadcrumb: [
          {
            text: 'Ürünler',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'tekliftopla',
      component: () => import('@/views/UrunListesi.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Ürün Listesi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/siparislerim',
      name: 'siparislerim',
      component: () => import('@/views/Siparislerim.vue'),
      meta: {
        pageTitle: 'Siparislerim',
        breadcrumb: [
          {
            text: 'UI',
          },
          {
            text: 'Siparislerim',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cariHesap',
      name: 'cariHesap',
      component: () => import('@/views/CariHesap.vue'),
      meta: {
        pageTitle: 'Cari Hesap',
        breadcrumb: [
          {
            text: 'UI',
          },
          {
            text: 'Cari Hesap',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'page-login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotpassword',
      name: 'page-forgot-password',
      component: () => import('@/views/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resetpassword/:code',
      name: 'page-reset-password',
      props: true,
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach((to, from, next) => {

  var userToken = localStorage.getItem("B2BUserToken");

  if (
    to.name == "page-login" ||
    to.name == "page-forgot-password" ||
    to.name == "page-reset-password"
  ) {
    if (userToken == null) next();
    else next("/");
  } else {
    if (userToken == null) next("/login");
    else next();
  }


})

export default router

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import axios from './libs/axios'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

Vue.prototype.$http = axios

axios.interceptors.request.use(config => {
  // Token Bilgisi Global Olarak
  const token = localStorage.getItem('B2BUserToken')
  config.headers.Authorization = `Bearer ${token}`
  config.headers['Content-Type'] = 'application/json'
  return config
})

axios.interceptors.response.use(null, async error => {
  if (error.response.status == 401) {
    localStorage.removeItem('B2BUserToken')
    router.push({
      name: 'login',
    })
    LoadingRemoving()
  }
  if (error.response.status == 403) {
    router.push({
      name: 'page-error-403',
    })
    LoadingRemoving()
  }
  return Promise.reject(error)
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
